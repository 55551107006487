/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Nato Sons, "Helvetica Neue", sans-serif;
}
.ngselectfield .ng-select.ng-select-single .ng-select-container {
  height: 50px;
}
.mat-dialog-container {
  
  background-color:lightgreen;
 
}
.mat-body, .mat-body-1, .mat-typography {
  font-family: Nato Sons, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-card{
  font-family:  Nato Sons, "Helvetica Neue", sans-serif;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.301);
}
